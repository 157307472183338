body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100dvw;
  height: 100dvh;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  background-color: rgb(242, 242, 245);
}

hr {
  width: 100%;
  height: 1px;
  border: lightgrey;
  background-color: lightgrey;
}

/* Custom scrollbar styles for all elements with overflow */
* {
  /* Apply smooth scrolling */
  scroll-behavior: smooth;
}

/* Style the scrollbar track (the background of the scrollbar) */
::-webkit-scrollbar {
  width: 8px; /* Width for vertical scrollbar */
  height: 8px; /* Height for horizontal scrollbar */
}

/* Style the scrollbar track when hovering */
::-webkit-scrollbar:hover {
  background-color: rgba(169, 169, 169, 0.3); /* Light gray, semi-transparent */
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.7); /* Light gray, semi-transparent */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid rgba(169, 169, 169, 0.3); /* Optional: border around the thumb */
}

/* Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(169, 169, 169, 1); /* Darker gray when hovered */
}

/* Style the scrollbar corner (the bottom-right corner where both scrollbars meet) */
::-webkit-scrollbar-corner {
  background-color: transparent; /* No background for the corner */
}
